@import "webfont-AzoWeb";

/*-------------- GLOBAL
*/

* {
  font-family: 'AzoWeb', sans-serif;
  font-weight: lighter;
}

a {
  text-decoration: none !important;
}

#footer-container,
#header-container,
#contact-container {
  text-align: center;
}

#footer-container {
  text-transform: uppercase;
}

h1 {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  background-image: url('/stackdev-300.png');
  height: 300px;
  width: 300px;
  // text-align: right;
  // text-transform: uppercase;
  // font-size: 20px;
  // border: 1px solid black;
  // display: inline-block;
  // padding: 10px;
  // position: fixed;
  // top: 0;
  // right: 0;
}

h2 {
  text-align: center;
  margin-bottom: 0px;
}

hr {
  width: 30em;
  max-width: 80%;
  height: 4px;
  border: 1px solid;
  margin-bottom: 30px;
}

.container {
  width: 800px;
  max-width: 98%;
  display: block;
  margin: 0 auto;
  position: relative;
  overflow: auto;
}

.column {
  display: inline-block;
  vertical-align: top;
}

.left-column {
  width: 47%;
  padding-right: 3%;
}

.right-column {
  width: 46%;
  padding-left: 3%;
}

.scrolling-box {
  // background-color: #fcf;
  width: 380px;
}

.d3-box {
  // background-color: #ffc;
  position: absolute;
  width: 400px;
  margin-left: 400px;
  top: 0;
}

.d3-box.graph-scroll-fixed {
  position: fixed;
}

.d3-box.graph-scroll-below{
  position: absolute;
  bottom: 0px;
  top: initial;
}

.scrolling-box > div {
  opacity: .3
}

.scrolling-box div.graph-scroll-active {
  opacity: 1;
}

#contact-container {
  display: block;
  text-align: center;
  position: relative;
}

.contact-form-fields {
  position: absolute;
  top: 2em;
  left: 50px;
  width: 710px;
}

input {
  display: block;
  width: 100%;
  margin-top: 1.5em;
  font-size: 1.5em;
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 5px 5px 0;
}

input[type="submit"] {
  font-size: 1em;
  line-height: 2.5em;
  border: none;
  background-color: #eee;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #000;
    color: #fff;
    transition: all 0.2s ease-in-out;
  }
}

.client {
  margin-bottom: 2em;
  text-align: center;

  img {
    max-width: 150px;
    height: auto;
  }

  p {
    margin-top: 0;
  }
}

.fa {
  display: inline;
  color: #000;
}

/*-------------- TUMBLR
*/

.tumblr-title {
  font-size: 1.25em;
  text-transform: uppercase;
  display: block;
}

/*-------------- MEDIA QUERIES
*/

.mobile-show {
  display: block;

  .contact-form-fields {
    position: relative;
    width: 95%;
    left: initial;
    top: initial;
  }
}
.mobile-hide {
  display: none;
}

@media (max-width: 809px) {
  .column {
    display: block;
    width: 99%;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    margin-bottom: 2em;
  }
}

@media (min-width: 810px) {
  .mobile-show {
    display: none;
  }
  .mobile-hide {
    display: block;
  }
}
